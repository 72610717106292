






























































































































import { Mixins, Component, Vue } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { CustomerOrderDetailDialogProperties } from "./CustomerOrderDetailDialogView.vue";
import { CustomerOrderCreateDialogProperties } from "./CustomerOrderCreateDialogView.vue";
import { CustomerOrderEditorControlProperties } from "./CustomerOrderEditorControlView.vue";
import { CustomerOrderDetailMoveProperties } from './CustomerOrderDetailMoveView.vue';
import { CustomerOrderCustomerProperties } from './CustomerOrderCustomerView.vue';
import InfoViewMixin from "@/components/shared/InfoViewMixin";
import EventManager from "@/utilities/EventManager";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import DateUtil from '@/utilities/DateUtil';
import ResponseUtil from '@/utilities/ResponseUtil';
import CustomerOrderService from "@/services/CustomerOrderService";

const CustomerOrderEditorControlView = () => import("./CustomerOrderEditorControlView.vue");
const AlertDialogView = () => import("@/components/shared/AlertDialogView.vue");
const CustomerOrderDetailView = () => import("./CustomerOrderDetailView.vue");
const CustomerOrderDetailDialogView = () => import("./CustomerOrderDetailDialogView.vue");
const CustomerOrderCreateDialogView = () => import("./CustomerOrderCreateDialogView.vue");
const CustomerOrderDetailMoveView = () => import("./CustomerOrderDetailMoveView.vue");
const CustomerOrderCustomerView = () => import("./CustomerOrderCustomerView.vue");

class CustomerOrderInfoProperties {
    customerOrder: any = { customer: {} };
    dateTimeFormat: string = null;
    events = new EventManager();
}

export { CustomerOrderInfoProperties };

@Component({
    components: {
        CustomerOrderEditorControlView,
        AlertDialogView,
        CustomerOrderDetailView,
        CustomerOrderDetailDialogView,
        CustomerOrderCreateDialogView,
        CustomerOrderDetailMoveView,
        CustomerOrderCustomerView
    },
    data: () => ({
        saving: false,
    }),
})
export default class CustomerOrderInfoView extends Mixins(InfoViewMixin) {
    private properties = new CustomerOrderInfoProperties();
    private editorControl = new CustomerOrderEditorControlProperties();
    private alertDialog = new AlertDialogProperties();
    private customerOrderDetailMove = new CustomerOrderDetailMoveProperties();
    private customerOrderDetailDialog = new CustomerOrderDetailDialogProperties();
    private customerOrderCreateDialog = new CustomerOrderCreateDialogProperties();
    private customerOrderCustomer = new CustomerOrderCustomerProperties();
    private customerOrderService = new CustomerOrderService();

    public get customerOrder() {
        return this.properties.customerOrder;
    }

	public get customer() {
		return this.customerOrder.customer;
	}

	public get customerUrl() {
		return `/management/customers/modify?id=${this.customer.id}`;
	}

    public get orderDate() {
        const dateTimeFormat = this.properties.dateTimeFormat;
        const v = this.customerOrder.orderDate;
        return v ? DateUtil.format(new Date(v), dateTimeFormat) : "";
    }

    public get customerText() {
        const v = this.customer;
		const f = v.facebookAccount;
		const n = v.name;
		const u = f.uniqueNo ?? null;
		const p = f.pageScopedId;

		const t0 = u !== null ? `${n} [${u}]` : n;
		const t1 = p !== "" ? `(${p})` : "";

        return v ? `${v.code} - ${t0} ${t1}`.trim() : null;
    }

    public get isDraft() {
        return this.customerOrder.status === 'Draft';
    }

    public formatStatus(v: string) {
		if ((v ?? "") === "") return "";
		return this.$t("text." + v.toLowerCase());
    }

    public refreshEditorControl(customerOrder: any) {
        if (customerOrder.status === "Draft") {
            this.editorControl.saveEnabled = true;
            this.editorControl.finalizeEnabled = true;
            this.editorControl.deliveredEnabled = false;
            this.editorControl.reverseEnabled = false;
            this.editorControl.cancelEnabled = false;
            this.editorControl.rejectEnabled = false;
            this.editorControl.deleteEnabled = true;
        } else if (customerOrder.status === "To-Pay") {
            this.editorControl.saveEnabled = true;
            this.editorControl.finalizeEnabled = false;
            this.editorControl.deliveredEnabled = false;
            this.editorControl.reverseEnabled = false;
            this.editorControl.cancelEnabled = true;
            this.editorControl.rejectEnabled = true;
            this.editorControl.deleteEnabled = false;
        } else if (customerOrder.status === "Paid") {
            this.editorControl.saveEnabled = false;
            this.editorControl.finalizeEnabled = false;
            this.editorControl.deliveredEnabled = true;
            this.editorControl.reverseEnabled = false;
            this.editorControl.cancelEnabled = false;
            this.editorControl.rejectEnabled = false;
            this.editorControl.deleteEnabled = false;
        } else if (customerOrder.status === "Expired") {
            this.editorControl.saveEnabled = false;
            this.editorControl.finalizeEnabled = false;
            this.editorControl.deliveredEnabled = false;
            this.editorControl.reverseEnabled = true;
            this.editorControl.cancelEnabled = false;
            this.editorControl.rejectEnabled = false;
            this.editorControl.deleteEnabled = false;
        } else {
            this.editorControl.saveEnabled = false;
            this.editorControl.finalizeEnabled = false;
            this.editorControl.deliveredEnabled = false;
            this.editorControl.reverseEnabled = false;
            this.editorControl.cancelEnabled = false;
            this.editorControl.rejectEnabled = false;
            this.editorControl.deleteEnabled = false;
        }
    }

    public created() {
        this.customerOrderCreateDialog.events.subscribe("created", this.createdRecord);
        this.customerOrderCustomer.events.subscribe('selected', this.selectedCustomer);
        this.properties.events.subscribe('load-header', this.load);
        this.mode = this.$route.params.mode;

        if (!this.checkMode() || this.isView() || this.isCreate()) {
            AlertDialogProperties.error(this.alertDialog, null, this.$t("message.invalid-mode"));
            this.alertDialog.tag = { command: "invalid-mode" };
            this.alertDialog.visible = true;
        } else {
            this.load();
        }
    }

    public destroyed() {
        this.customerOrderCreateDialog.events.remove("created", this.createdRecord);
        this.customerOrderCustomer.events.remove('selected', this.selectedCustomer);
        this.properties.events.remove('load-header', this.load);
    }

    public async load(loadDetails: boolean = true) {
        try {
            if (this.isModify()) {
                var id = this.$route.query.id;
                const r = await this.customerOrderService.get({ 
					id, 
					loadCustomer: true,
					loadFacebookAccount: true
				});
                if (r.data.customerOrders.length === 0) {
                    throw new Error(this.$t("message.record-not-found").toString());
                }

                const customerOrder = r.data.customerOrders[0];
                this.properties.dateTimeFormat = ResponseUtil.getDateTimeFormat(r);
                this.properties.customerOrder = customerOrder;
                this.refreshEditorControl(customerOrder);

				if (loadDetails) {
    	            await this.properties.events.fire("load-details");
				}
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.tag = { command: "load-error" };
                this.alertDialog.visible = true;
            }
        }
    }

    public create() {
        this.customerOrderCreateDialog.visible = true;
    }

    public async createdRecord(customerOrder: any) {
        await this.$router.push("/management/customer/customer-orders/modify?id=" + customerOrder.id);
        this.$route.query.id = customerOrder.id;
        this.mode = "modify";
        this.load();
    }

    public async save() {
        if (this.$data.saving) {
            return;
        }

        this.$data.saving = true;
        try {
            await this.customerOrderService.put(this.customerOrder);
			this.load(false);
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public async finalize() {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
            await this.customerOrderService.finalize(this.customerOrder);
			this.load(false);
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public async cancel() {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
            await this.customerOrderService.cancel(this.customerOrder);
			this.load(false);
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public async reject() {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
            await this.customerOrderService.reject(this.customerOrder);
			this.load(false);
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public async delivered() {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
            await this.customerOrderService.delivered(this.customerOrder);
			this.load();
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public async reverse() {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
            await this.customerOrderService.reverse(this.customerOrder);
			this.load();
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public async remove() {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
            await this.customerOrderService.delete(this.customerOrder);
            AlertDialogProperties.deleted(this.alertDialog, null, null);
            this.alertDialog.tag = { command: "record-deleted" };
            this.alertDialog.visible = true;
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public finalizeDialog() {
        const t = this.$t("title.finalize-order");
        const m = this.$t("message.finalize-order");
        AlertDialogProperties.standard(this.alertDialog, t, m);
        this.alertDialog.buttons = [this.$t("text.yes"), this.$t("text.no")];
        this.alertDialog.primaryButton = this.$t("text.yes");
        this.alertDialog.tag = { command: "finalize" };
        this.alertDialog.visible = true;
    }

    public cancelDialog() {
        const t = this.$t("title.cancel-order");
        const m = this.$t("message.cancel-order1");
        AlertDialogProperties.standard(this.alertDialog, t, m);
        this.alertDialog.buttons = [this.$t("text.yes"), this.$t("text.no")];
        this.alertDialog.primaryButton = this.$t("text.yes");
        this.alertDialog.tag = { command: "cancel" };
        this.alertDialog.visible = true;
    }

    public rejectDialog() {
        const t = this.$t("title.reject-order");
        const m = this.$t("message.reject-order");
        AlertDialogProperties.standard(this.alertDialog, t, m);
        this.alertDialog.buttons = [this.$t("text.yes"), this.$t("text.no")];
        this.alertDialog.primaryButton = this.$t("text.yes");
        this.alertDialog.tag = { command: "reject" };
        this.alertDialog.visible = true;
    }

    public deliveredDialog() {
        const t = this.$t("title.delivered-order");
        const m = this.$t("message.delivered-order");
        AlertDialogProperties.standard(this.alertDialog, t, m);
        this.alertDialog.buttons = [this.$t("text.yes"), this.$t("text.no")];
        this.alertDialog.primaryButton = this.$t("text.yes");
        this.alertDialog.tag = { command: "delivered" };
        this.alertDialog.visible = true;
    }

    public reverseDialog() {
        const t = this.$t("title.reverse-order");
        const m = this.$t("message.reverse-order");
        AlertDialogProperties.standard(this.alertDialog, t, m);
        this.alertDialog.buttons = [this.$t("text.yes"), this.$t("text.no")];
        this.alertDialog.primaryButton = this.$t("text.yes");
        this.alertDialog.tag = { command: "reverse" };
        this.alertDialog.visible = true;
    }

    public removeDialog() {
        AlertDialogProperties.delete(this.alertDialog, null);
        this.alertDialog.tag = { command: "remove" };
        this.alertDialog.visible = true;
    }

    public async control(button: string) {
        if (button === "create") {
            this.create();
        } else if (button === "save") {
            await this.save();
        } else if (button === "finalize") {
            this.finalizeDialog();
        } else if (button === "cancel") {
            this.cancelDialog();
        } else if (button === "reject") {
            this.rejectDialog();
        } else if (button === "delivered") {
            this.deliveredDialog();
        } else if (button === "reverse") {
            this.reverseDialog();
        } else if (button === "delete") {
            this.removeDialog();
        }
    }

    public selectCustomer() {
        this.customerOrderCustomer.tag = "info";
        this.customerOrderCustomer.visible = true;
    }

    public selectedCustomer(customer: any) {
        const tag = this.customerOrderCustomer.tag;
        if (tag === "info") {
            this.customerOrder.customer = customer;
            this.customerOrder.customerId = customer.id;
            this.customerOrderCustomer.visible = false;
        }
    }

    public customerCleared() {
        this.customerOrder.customer = {};
        this.customerOrder.customerId = null;
    }

    public async alertDialogClicked(button: string) {
        const tag = this.alertDialog.tag;
        this.alertDialog.visible = false;
        if (tag) {
            if (tag.command === "finalize" && button === this.$t("text.yes")) {
                await this.finalize();
            } else if (tag.command === "cancel" && button === this.$t("text.yes")) {
                await this.cancel();
            } else if (tag.command === "reject" && button === this.$t("text.yes")) {
                await this.reject();
            } else if (tag.command === "delivered" && button === this.$t("text.yes")) {
                await this.delivered();
            } else if (tag.command === "reverse" && button === this.$t("text.yes")) {
                await this.reverse();
            } else if (tag.command === "remove" && button === this.$t("text.yes")) {
                await this.remove();
            }
        }
        await this.properties.events.fire("alert-dialog-clicked", button);
        await this.alertDialogClosed();
    }

    public async alertDialogClosed() {
        if (this.alertDialog.visible) {
            return;
        }

        const tag = this.alertDialog.tag;
        if (tag && (tag.command === "invalid-mode" || tag.command === "load-error" || tag.command === "record-deleted")) {
            await this.$router.push("/management/customer/customer-orders");
        }

        await this.properties.events.fire("alert-dialog-closed");
    }
}
