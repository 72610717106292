





























































































































import { Vue, Component, Prop } from "vue-property-decorator";

class CustomerOrderEditorControlProperties {
    createEnabled: boolean = true;
    saveEnabled: boolean = true;
    finalizeEnabled: boolean = true;
    deliveredEnabled: boolean = true;
    reverseEnabled: boolean = true;
    cancelEnabled: boolean = true;
    rejectEnabled: boolean = true;
    deleteEnabled: boolean = true;
}

export { CustomerOrderEditorControlProperties };

@Component({})
export default class CustomerOrderEditorControlView extends Vue {
    @Prop({ default: new CustomerOrderEditorControlProperties() })
    private properties: CustomerOrderEditorControlProperties;

    private clicked(button: string) {
        this.$emit("clicked", button);
    }
}
