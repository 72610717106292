var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"disabled":!_vm.properties.createEnabled,"large":"","icon":""},on:{"click":function($event){return _vm.clicked('create')}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('text.create-record')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"disabled":!_vm.properties.saveEnabled,"large":"","icon":""},on:{"click":function($event){return _vm.clicked('save')}}},on),[_c('v-icon',[_vm._v("mdi-content-save")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('text.save-changes')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"disabled":!_vm.properties.finalizeEnabled,"large":"","icon":""},on:{"click":function($event){return _vm.clicked('finalize')}}},on),[_c('v-icon',[_vm._v("mdi-check")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('text.finalize-order')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"disabled":!_vm.properties.deliveredEnabled,"large":"","icon":""},on:{"click":function($event){return _vm.clicked('delivered')}}},on),[_c('v-icon',[_vm._v("mdi-truck-delivery")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('text.delivered')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"disabled":!_vm.properties.reverseEnabled,"large":"","icon":""},on:{"click":function($event){return _vm.clicked('reverse')}}},on),[_c('v-icon',[_vm._v("mdi-undo")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('text.reverse')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"disabled":!_vm.properties.cancelEnabled,"large":"","icon":""},on:{"click":function($event){return _vm.clicked('cancel')}}},on),[_c('v-icon',[_vm._v("mdi-cancel")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('text.cancel-order')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"disabled":!_vm.properties.rejectEnabled,"large":"","icon":""},on:{"click":function($event){return _vm.clicked('reject')}}},on),[_c('v-icon',[_vm._v("mdi-file-remove")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('text.reject-order')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"disabled":!_vm.properties.deleteEnabled,"large":"","icon":""},on:{"click":function($event){return _vm.clicked('delete')}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('text.delete-record')))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }