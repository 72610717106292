



























































import { Vue, Component, Prop } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import EventManager from '@/utilities/EventManager';
import ExceptionUtil from "@/utilities/ExceptionUtil";
import CustomerOrderService from "@/services/CustomerOrderService";
import CustomerOrderDetailService from "@/services/CustomerOrderDetailService";

class CustomerOrderDetailMoveProperties {
    visible: boolean = false;
    customerOrder: any = {};
    selected: any[] = [];
    events = new EventManager();
}

export { CustomerOrderDetailMoveProperties };

@Component({
    data: () => ({
        type: "existing",
        orderId: null,
        orders: [],
        subOrder: false,
        status: "Draft",
        saving: false,
    }),
})
export default class CustomerOrderDetailMoveView extends Vue {
    @Prop({ default: new CustomerOrderDetailMoveProperties() })
    private properties: CustomerOrderDetailMoveProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private customerOrderService = new CustomerOrderService();
    private customerOrderDetailService = new CustomerOrderDetailService();

    public get title() {
        const t = this.$t('text.customer-order-detail');
        return t + ' - ' + this.$t('text.move-record');
    }

    public get statusOptions() {
        return [
            {
                text: this.$t("text.draft"),
                value: "Draft",
            },
            {
                text: this.$t("text.to-pay"),
                value: "To-Pay",
            },
        ];
    }

    public get isExisting() {
        return this.$data.type === "existing";
    }

    public get isNewOrder() {
        return this.$data.type === "new-order";
    }

    public created() {
        this.load();
    }

    public async load() {
        try {
            const customerOrder = this.properties.customerOrder;
            const r = await this.customerOrderService.get({
                customerId: customerOrder.customerId,
                status: "Draft,Unpaid",
            });
            const customerOrders = r.data.customerOrders
                .filter(x => x.id !== this.properties.customerOrder.id);
            this.$data.orders = customerOrders;
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public async submit() {
        try {
            if (this.isExisting && this.$data.orderId == null) {
                var m: any = this.$t("message.customer-order-not-selected");
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
                return;
            } 

            const orderId = this.isExisting ? this.$data.orderId : null;
            const subOrder = this.isNewOrder ? this.$data.subOrder : false;
            const selected = this.properties.selected.map((x) => x.id);
            const status = this.$data.status;

            await this.customerOrderDetailService.move(orderId, selected, status, subOrder);
            await this.properties.events.fire('moved');
            this.properties.visible = false;
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public cancel() {
        this.properties.visible = false;
    }
}
