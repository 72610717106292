



















































































import { Vue, Component, Prop } from "vue-property-decorator";
import { CustomerOrderInfoProperties } from './CustomerOrderInfoView.vue';
import { AlertDialogProperties } from '@/components/shared/AlertDialogView.vue';
import AutoNumeric, { Options } from 'autonumeric';
import CommonUtil from '@/utilities/CommonUtil';
import CustomerOrderDetailService from "@/services/CustomerOrderDetailService";
import MathService from '@/services/MathService';
import ExceptionUtil from '@/utilities/ExceptionUtil';
import AutoNumericUtil from "@/utilities/AutoNumericUtil";

class CustomerOrderDetailDialogProperties {
    isNew: boolean = false;
    visible: boolean = false;
    detail: any = {};
}

export { CustomerOrderDetailDialogProperties };

@Component({
    data: () => ({
        saving: false,
    }),
})
export default class CustomerOrderDetailDialogView extends Vue {
    @Prop({ default: new CustomerOrderDetailDialogProperties() })
    private properties: CustomerOrderDetailDialogProperties;
    @Prop() private customerOrderInfo: CustomerOrderInfoProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private customerOrderDetailService = new CustomerOrderDetailService();
    private mathService = new MathService();
    private inputQuantity: HTMLInputElement;
    private inputWeight: HTMLInputElement;
    private inputTotalWeight: HTMLInputElement;
    private inputPrice: HTMLInputElement;
    private inputTotalAmount: HTMLInputElement;

    private lastValue: any = {};

    public get title() {
        const isNew = this.properties.isNew;
        const t = this.$t("text.customer-order-detail");
        return (isNew ? this.$t('title.create') : this.$t('title.modify'));
    }

    public get customerOrder() {
        return this.customerOrderInfo.customerOrder;
    }

    public get isDraft() {
        return this.customerOrder.status === "Draft";
    }

    public get isToPay() {
        return this.customerOrder.status === "To-Pay";
    }

    public get detail() {
        return this.properties.detail;
    }

    public mounted() {
        var dialog = <Vue>this.$refs.content;
        if (dialog) {
            this.inputQuantity = dialog.$el.querySelector('#quantity');
            this.inputWeight = dialog.$el.querySelector('#weight');
            this.inputTotalWeight = dialog.$el.querySelector('#total-weight');
            this.inputPrice = dialog.$el.querySelector('#price');
            this.inputTotalAmount = dialog.$el.querySelector('#total-amount');

            this.updateFormatter();
        }
    }

    public updateFormatter() {
        setTimeout(() => {
            const options1: Options = {
                minimumValue: "0",
                historySize: 0,
                outputFormat: "number",
                digitGroupSeparator: "",
                unformatOnHover: false,
                unformatOnSubmit: false,
            };
            const options2: Options = CommonUtil.clone(options1);
            options2.decimalPlaces = 0;

            AutoNumericUtil.findOrNew(this.inputQuantity, null, options2)
                .set(this.detail.quantity ?? 0);
            AutoNumericUtil.findOrNew(this.inputWeight, null, options1)
                .set(this.detail.weight ?? 0);
            AutoNumericUtil.findOrNew(this.inputTotalWeight, null, options1)
                .set(this.detail.totalWeight ?? 0);
            AutoNumericUtil.findOrNew(this.inputPrice, null, options1)
                .set(this.detail.price ?? 0);
            AutoNumericUtil.findOrNew(this.inputTotalAmount, null, options1)
                .set(this.detail.totalAmount ?? 0);

            this.inputQuantity.addEventListener('blur', this.calculate);
            this.inputWeight.addEventListener('blur', this.calculate);
            this.inputPrice.addEventListener('blur', this.calculate);
            this.inputQuantity.addEventListener("keydown", this.calculate);
            this.inputWeight.addEventListener("keydown", this.calculate);
            this.inputPrice.addEventListener("keydown", this.calculate);
        }, 150);
    }

    public async calculate(e: Event) {
        if (e.type === "keydown" && (<KeyboardEvent>e).key !== "Enter") {
            return;
        }

        try {
            const quantity = Number(this.detail.quantity ?? 0);
            const weight = Number(this.detail.weight ?? 0);
            const price = Number(this.detail.price ?? 0);

            if (this.lastValue.quantity !== quantity ||
                this.lastValue.weight !== weight ||
                this.lastValue.price !== price) {
                
                const totalWeight = await this.mathService.round(quantity * weight, 2);
                const totalAmount = await this.mathService.round(quantity * price, 2);

                AutoNumericUtil.set(this.inputTotalWeight, totalWeight);
                AutoNumericUtil.set(this.inputTotalAmount, totalAmount);
            }

            this.lastValue.quantity = quantity;
            this.lastValue.weight = weight;
            this.lastValue.price = price;
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public async submit() {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
            
            if (this.properties.isNew) {
                this.detail.customerOrderId = this.customerOrder.id;
                await this.customerOrderDetailService.post(this.detail);
            } else {
                await this.customerOrderDetailService.put(this.detail);
            }
            await this.customerOrderInfo.events.fire('load-header');
            this.properties.visible = false;
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public close() {
        if (!this.$data.saving) {
            this.properties.visible = false;
        }
    }
}
