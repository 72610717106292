import AbstractService from "./AbstractService";
import Axios from "axios";

export default class MathService extends AbstractService {
    private controller = "math";

    public async round(value: number, decimalPlaces: number) {
        var q = { value, decimalPlaces };
        const url = this.makeUrlWithQueries(`${this.controller}/round`, q);
        return (await Axios.get<any>(url, this.makeConfig(false))).data;
    }
}